@font-face {
  font-family: silkaFonts;
  src: url("../public/fonts/Silka-Regular.otf");
}

body {
  /*background-color: #1d2233 !important;*/
  background-color: #f1eff0 !important;
  font-family: silkaFonts, serif !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.quickSelectTab {
  background-color: #1d2233;
  border-radius: 8px;
  color: #ffffff;
  padding: 10px 10px 10px 20px;
  min-height: 150px;
}

.quickSelectTab h4 {
  color: #ff4e50;
}

.quickSelectTab p {
  font-size: 12px;
}

.quickSelectTab hr {
  border-bottom: 1px solid #ff4e50 !important;
}

.statsTable {
  font-size: 12px;
  color: #ffffff;
}

.statsTable tr {
  height: 30px;
}

.navbar_top {
  background-color: #1d2233;
  padding: 20px;
  text-align: right;
}

.navbar_top .companyLogo {
  text-align: left;
}

.companyLogo img {
  height: 50px;
}

.navbar_top_list {
  padding-top: 10px;
}

ul.navbar_top_list li {
  display: inline;
  padding: 10px;
  margin-top: 20px;
}

ul.navbar_top_list li a {
  color: #ffffff;
  font-weight: bold;
}

ul.navbar_top_list li a:hover {
  color: #ff4e50;
  font-weight: bold;
}

.selectionBar {
  background-color: #ffffff;
  padding: 10px;
}

.dashContainer {
  background-color: #ffffff;
  /*border: 1px solid #ffffff;*/
  border: 1px solid #1d2233;
  margin: 20px;
  min-height: 900px;
}

.dashContainer .grid-x .cell {
  padding: 20px;
}

.dashContainerHeader {
  background-color: #1d2233;
  /*border-bottom: 1px solid #ffffff;*/
  border-bottom: 1px solid #1d2233;
  /*color: #ffffff;*/
  color: #ff4e50;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 30px;
  text-align: left;
}

.dashContainerHeader span.dataSubNote {
  color: #ffffff;
  font-size: 12px;
  font-weight: normal;
}

.dashContainerCompact {
  background-color: #ffffff;
  /*border: 1px solid #ffffff;*/
  border: 1px solid #1d2233;
  margin: 20px;
  min-height: 400px;
}

.dashContainerCompact .grid-x .cell {
  padding: 20px;
}

.dashContainerHeaderCompact {
  background-color: #1d2233;
  /*border-bottom: 1px solid #ffffff;*/
  border-bottom: 1px solid #1d2233;
  /*color: #ffffff;*/
  color: #ff4e50;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 30px;
  text-align: left;
}

.dashContainerHeaderCompact span.dataSubNote {
  color: #ffffff;
  font-size: 12px;
  font-weight: normal;
}

.login-page {
  padding: 50px;
}

.helpContainer {
  padding: 50px;
}

.energyNavigation {
  background-color: #1d2233;
  color: #ffffff;
  min-height: 100%;
  padding: 20px;
}

.energyNavigation h5 {
  color: #ff4e50;
}

.energyNavigation ul {
  list-style: none;
}

.energyNavigation ul li a {
  color: #ffffff;
  font-weight: bold;
}

.energyNavigation ul li a:hover {
  color: #ff4e50;
  font-weight: bold;
}

.energyNavigation ul.submenuNav {
  list-style: none;
  font-size: 14px;
}

.energyNavigation ul.submenuNav li {
  list-style: none;
  font-size: 14px;
  height: 35px;
}

.energyNavigation ul.submenuNav li a {
  color: #ffffff;
  font-weight: bold;
  margin-left: 15px;
}

.energyNavigation ul.submenuNav li a:hover {
  color: #ff4e50;
  font-weight: bold;
}

.scopeEmissionsTable {
  border: 2px solid #999999;
  border-radius: 10px;
  padding: 15px;
}

.scopeEmissionsTable hr {
  width: 100%;
  color: #ff4e50;
}

.emissionsTable {
  background-color: #ffffff !important;
  border: 1px solid #000000;
  font-size: 14px;
}

.emissionsTable thead tr td, .emissionsTable thead tr th, .emissionsTable tbody tr td {
  background-color: #ffffff !important;
  border: 1px solid #cccccc;
}

.k-sparkline {
  margin-left: 5% !important;
  width: 90% !important;
  height: 50px;
}

.k-datepicker {
  height: 41px !important;
}

.padRegion {
  padding-top: 8px;
}

.k-panelbar {
  background-color: #ff4e50 !important;
}

.k-slider {
  width: 100% !important;
}

.padAlignRight {
  text-align: right;
  margin-right: 10px;
}

a.linkBack {
  color: #1d2233;
}

a.linkBack:hover {
  color: #ff4e50;
}

.k-button {
  background-color: #ff4e50 !important;
  color: #ffffff !important;
  border-color: #ffffff !important;
}

.k-button:hover {
  background-color: #1d2233 !important;
  color: #ffffff !important;
  border-color: #ffffff !important;
}

tr.scopeOneHeadRow {
  background-color: #a9d08e !important;
  color: #000000 !important;
}

tr.scopeTwoHeadRow {
  background-color: #9bc2e6 !important;
  color: #000000 !important;
}

tr.scopeThreeHeadRow {
  background-color: #f4b084 !important;
  color: #000000 !important;
}

tr.scopeOneLabelRow {
  background-color: #c8f6a8 !important;
  color: #000000 !important;
}

tr.scopeTwoLabelRow {
  background-color: #abd1ff !important;
  color: #000000 !important;
}

tr.scopeThreeLabelRow {
  background-color: #f4b68e !important;
  color: #000000 !important;
}

tr.scopeOneContentRow {
  background-color: #dfffca !important;
  color: #000000 !important;
}

tr.scopeTwoContentRow {
  background-color: #badeff !important;
  color: #000000 !important;
}

tr.scopeThreeContentRow {
  background-color: #f4bd9a !important;
  color: #000000 !important;
}

td.boldNoIndent {
  font-weight: bold !important;
}

td.boldWithIndent {
  font-weight: bold !important;
  padding-left: 10px !important;
}

.notesHeading {
  color: #666666;
  border: 1px solid #ff4e50;
  border-radius: 8px;
  font-size: 12px;
  font-style: italic;
  padding: 12px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.k-pager span.k-button-text {
  color: #ff4e50;
}

.k-pager span.k-button-icon {
  color: #0a0a0a;
}

.energyEstimate {
  padding: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.energyEstimate span {
  background: rgb(255, 78, 80);
  width: 13px;
  height: 3px;
  margin-right:5px;
}
.energyEstimate span.yellow {
  background: #c8a623;
}

.date-filter-form {
  display: flex;
}
.date-filter-form .k-button {
  height: 40px;
}

.waterNavigation {
  background-color: #1d2233;
  color: #ffffff;
  min-height: 100%;
  padding: 20px;
}

.waterNavigation h5 {
  color: #ff4e50;
}

.waterNavigation ul {
  list-style: none;
}

.waterNavigation ul li a {
  color: #ffffff;
  font-weight: bold;
}

.waterNavigation ul li a:hover {
  color: #ff4e50;
  font-weight: bold;
}

.waterNavigation ul.submenuNav {
  list-style: none;
  font-size: 14px;
}

.waterNavigation ul.submenuNav li {
  list-style: none;
  font-size: 14px;
  height: 35px;
}

.waterNavigation ul.submenuNav li a {
  color: #ffffff;
  font-weight: bold;
  margin-left: 15px;
}

.waterNavigation ul.submenuNav li a:hover {
  color: #ff4e50;
  font-weight: bold;
}

.bio-status-box {
  background-color: #f4f4f4;
  background-position: 95%;
  background-repeat: no-repeat;
  border: 1px solid #272525;
  border-radius: 5px;
  margin-bottom: 8px;
  padding: 24px 32px 24px 24px;
}
.bio-data {
  display: block;
  text-align: left;
  width: 50%;
}

.chart-height{
  min-height :600px;
}
.chart-tab-width {
  width: 100% !important;
}

.k-tabstrip-content .k-animation-container {
  width:100% !important;
}

.border-div {
  border: 1px solid #3b3b3b;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 15px;
}
.k-numerictextbox {
  height :40px !important;
}

.k-filtercell-operator { display: none;}